body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

input,
select {
  outline: none;
}

.login-page,
.report-page {
  width: 100%;
  min-height: 100vh;
  background-image: url(./bg.jpg);
  background-position: center 50%;
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
}


.login-page::after,
.report-page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to left, #272a37db, #272a3785);
}

.heading {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 6rem;
  /* margin-top: -3rem; */
}

.login-form {
  padding: 2rem;
  background-color: #272a37e0;
  box-shadow: 0px 0px 30px -19px #fff;
  width: 30rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-width: 30rem; */
  z-index: 9999;
  position: relative;
}

.form-body {
  width: 85%;
  margin: 0 auto;
}

.form-group {
  width: 100%;
  position: relative;
}

.form-group label {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}

.form-group input,
.form-group select {
  box-sizing: border-box;
  width: 100%;
  height: 2.7rem;
  border: 0;
  border-radius: 3px;
  background-color: #fff;
  margin: 0.5rem auto;
  margin-bottom: 2rem;
  padding: 0 1rem;
  font-size: 1.1rem;

}

.date-group input {
  font-size: .8rem;
}

.btn {
  background-color: #1d90f5;
  /* #1d90f5 */
  border: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: .8rem;
  border-radius: 50px;
  margin: 1rem;
  width: 40%;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  left: 0;
}


.form-action {
  text-align: center;
}


/* loading  */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  /* height: 350px; */
}




/* global inputs  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/* responsive */

@media (max-width: 700px) {
  html {
    font-size: 85%;
  }

  .login-page::after,
  .report-page::after {
    background-image: linear-gradient(to left, #272a37f0, #1418289e);
  }

  .login-form {
    background-color: transparent;
    width: 100%;
    min-width: 100%;
    padding: 1rem;
  }
}